import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  BackButton,
  H2,
  H4,
  Spacer,
  InvisibleOnMobile,
  InvisibleOnDesktop,
  FilterButton,
  BootstrapContainer,
} from "../../../common/common.style"
import Filter from "../filters/filter"
import SearchInput from "../header/header"
import {
  ArticleTitle,
  ArticleWrap,
  ExerciseCol,
  ExercisesRow,
  FeaturedImage,
  FeaturedImageWrapper,
  SectionContainer,
  ViewAllButton,
  FullScreen,
  CardSubtitle,
  SeeExercise,
  Cardparagraph,
} from "./articles.style"
import { Container, Row, Col } from "reactstrap"
import {
  InnerContainer,
  MenuItem,
  MenuItemIcon,
} from "../../../common/topBar.style"
import { Modal } from "../../../common/Modal"
const isBrowser = typeof window !== "undefined"

const Articles = ({
  articles,
  searchInput,
  clearSeachInput,
  handleSearchChange,
  handleApplyFilters,
  promoCodes,
}) => {
  const dataFilter = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "tobsosLogoFinal.png" }) {
        id
        extension
        publicURL
      }
      close: file(relativePath: { eq: "x.svg" }) {
        id
        extension
        publicURL
      }
      allStrapiFilters(
        sort: { order: [ASC, ASC], fields: [id, categories___id] }
      ) {
        edges {
          node {
            id
            title
            title_RO
            categories {
              id
              title
              title_RO
            }
          }
        }
      }

      allStrapiSubCategories(sort: { fields: id, order: ASC }) {
        edges {
          node {
            categories {
              title
              title_RO
              id
            }
            title
            title_RO
            id
          }
        }
      }

      allStrapiAgeCategories(sort: { fields: id, order: ASC }) {
        edges {
          node {
            id
            title
            title_RO
            description
            featured_image {
              absolutePath
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const [modal, setModal] = useState(false)
  const [visible, setVisible] = useState(6)
  const [filters, setFilters] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const toggle = () => {
    setModal(!modal)
  }

  const nextLink = article => {
    isBrowser &&
      window &&
      window.localStorage.setItem(
        "toLink",
        article.title.toLowerCase().split(" ").join("-")
      )
    return true
  }

  useEffect(() => {
    const newFilters = []

    if (dataFilter.allStrapiAgeCategories) {
      const newCategories = []
      dataFilter.allStrapiAgeCategories.edges.map(category => {
        newCategories.push(category.node)
      })
      newFilters.push({
        title: "Age Categories",
        title_RO: "Categorii de vârstă",
        id: "age_categories",
        categories: newCategories,
      })
    }
    if (dataFilter.allStrapiFilters) {
      dataFilter.allStrapiFilters.edges.map(filter => {
        const categories = filter.node.categories
        let updatedCategories = []
        if (dataFilter.allStrapiSubCategories) {
          dataFilter.allStrapiSubCategories.edges.map(
            ({ node: subCategory }) => {
              const newCategories = categories.map(category => {
                const isElement = !!subCategory.categories.find(
                  element => element.id === category.id
                )
                if (isElement) {
                  const newSubCategory = {
                    title: subCategory.title,
                    title_RO: subCategory.title_RO,
                    id: subCategory.id,
                  }
                  const subcategories = category.sub_categories
                    ? [...category.sub_categories, newSubCategory]
                    : [
                        {
                          title: "View All",
                          title_RO: "Vezi Toate",
                          id: category.id,
                        },
                        newSubCategory,
                      ]
                  category.sub_categories = subcategories
                  return {
                    ...category,
                  }
                }
                return category
              })
              updatedCategories = newCategories
            }
          )
        }
        newFilters.push({
          ...filter.node,
          categories: updatedCategories.sort((a, b) => a.id - b.id),
        })
      })
    }
    setFilters(newFilters)
  }, [dataFilter])

  const printCardParagraph = (article, filterId) => {
    let isFilterRelevant = false
    article.categories.forEach(category => {
      if (filterId === category.filter) {
        isFilterRelevant = true
      }
    })
    if (isFilterRelevant) {
      return (
        <Cardparagraph>
          {isBrowser && window && window.localStorage.getItem("lang") === "en"
            ? (
                article.categories &&
                article.categories
                  .map(categorie =>
                    categorie.filter === filterId ? categorie.title + " " : ""
                  )
                  .toString()
              )
                .substring(0, 65)
                .split(",")
            : (
                article.categories &&
                article.categories
                  .map(categorie =>
                    categorie.filter === filterId
                      ? categorie.title_RO + " "
                      : ""
                  )
                  .toString()
              )
                .substring(0, 65)
                .split(",")}
        </Cardparagraph>
      )
    } else {
      return <Cardparagraph>-</Cardparagraph>
    }
  }

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={value => setShowModal(value)}
        promoCodes={promoCodes}
      />
      <SectionContainer fluid>
        <BootstrapContainer>
          <Row center>
            <Col
              xl={{ size: 3 }}
              lg={{ size: 4 }}
              md={{ size: 4 }}
              sm={{ size: 12 }}
            >
              <H2 malign="center">{t("articlesComponent.filters")}</H2>
              <Container fluid>
                <Row>
                  <Col
                    lg={{ size: 12 }}
                    md={{ size: 12 }}
                    sm={{ size: 8 }}
                    xs={{ size: 8 }}
                    style={{ marginLeft: "-15px" }}
                  >
                    <SearchInput
                      handleSearchChange={handleSearchChange}
                      searchInput={searchInput}
                      clearSeachInput={clearSeachInput}
                    />
                  </Col>
                  <Col sm={{ size: 4 }} xs={{ size: 4 }}>
                    <InvisibleOnDesktop>
                      <FilterButton onClick={() => toggle()}>
                        {t("articlesComponent.filters")}
                      </FilterButton>
                    </InvisibleOnDesktop>
                  </Col>
                </Row>
              </Container>

              <InvisibleOnMobile>
                {filters.map(filter => (
                  <Filter
                    key={filter.key}
                    categoryKey={filter.id}
                    title={filter.title}
                    title_RO={filter.title_RO}
                    categories={filter.categories}
                    handleApplyFilters={handleApplyFilters}
                  />
                ))}
              </InvisibleOnMobile>
            </Col>

            <Col
              xl={{ size: 9 }}
              lg={{ size: 8 }}
              md={{ size: 8 }}
              sm={{ size: 12 }}
            >
              <H2 malign="center" style={{ marginBottom: "2rem" }}>
                {t("articlesComponent.exercises")}
              </H2>
              <H4 malign="center">
                {searchInput
                  ? articles.length > 0
                    ? t("articlesComponent.searchResults") +
                      '"' +
                      searchInput +
                      '"'
                    : t("articlesComponent.noSearchResults") +
                      '"' +
                      searchInput +
                      '"'
                  : ""}
              </H4>
              {searchInput ? (
                <BackButton
                  onClick={clearSeachInput}
                  style={{ marginBottom: "2rem" }}
                >
                  {t("articlesComponent.backToAllExercises")}
                </BackButton>
              ) : (
                ""
              )}
              <Container fluid>
                <ExercisesRow>
                  {articles.length > 0 &&
                    articles.map(
                      (article, i) =>
                        i < visible && (
                          <ExerciseCol
                            key={i}
                            lg={{ size: 4 }}
                            md={{ size: 6 }}
                            sm={{ size: 12 }}
                            article={true ? 1 : 0}
                            onClick={() => {
                              if (
                                window &&
                                window.localStorage.getItem("codeActive")
                              ) {
                                nextLink(article)
                                window.location.href =
                                  "/exercise/" + article.slug
                              } else {
                                article.Paid &&
                                  nextLink(article) &&
                                  setShowModal(true)
                              }
                            }}
                          >
                            <ArticleWrap
                              to={
                                !article.Paid
                                  ? "/exercise/" + article.slug
                                  : undefined
                              }
                              data-aos="fade-up"
                              data-aos-offset="0"
                              data-aos-delay={50}
                              data-aos-duration="500"
                              data-aos-easing="ease-out"
                            >
                              <FeaturedImageWrapper
                                src={article.image}
                                isPaid={
                                  window
                                    ? window.localStorage.getItem("codeActive")
                                      ? false
                                      : article.Paid
                                    : article.Paid
                                }
                              >
                                <FeaturedImage
                                  src={article.image}
                                  alt={article.title}
                                />
                              </FeaturedImageWrapper>
                              {/* <Date>{article.date}</Date> */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <ArticleTitle>
                                  {article.title.substring(0, 65)}
                                  {article.title >
                                    article.title.substring(0, 65) && " ..."}
                                </ArticleTitle>
                                <CardSubtitle>
                                  {t("articlesComponent.bodyPart")}
                                </CardSubtitle>
                                {printCardParagraph(article, 1)}

                                <CardSubtitle>
                                  {t("articlesComponent.equipment")}
                                </CardSubtitle>
                                {printCardParagraph(article, 5)}

                                <CardSubtitle>
                                  {t("articlesComponent.ageCategories")}
                                </CardSubtitle>
                                <Cardparagraph>
                                  {isBrowser &&
                                  window &&
                                  window.localStorage.getItem("lang") === "en"
                                    ? article.age_categories.length > 0
                                      ? article.age_categories.map(
                                          age => age.title + " "
                                        )
                                      : t("articlesComponent.allAges")
                                    : article.age_categories.length > 0
                                    ? article.age_categories.map(
                                        age => age.title_RO + " "
                                      )
                                    : t("articlesComponent.allAges")}
                                </Cardparagraph>

                                {/* <Meta>Reading time about {article.readingTime}</Meta> */}
                              </div>
                              <SeeExercise>
                                {t("articlesComponent.seeExercises")}
                              </SeeExercise>
                            </ArticleWrap>
                            <Spacer height={1} tHeight={0} mHeight={0} />
                          </ExerciseCol>
                        )
                    )}
                  {/* <div>
                    <button onClick={() => setShowModal(true)}>
                      The Modal Test
                    </button>
                  </div> */}
                </ExercisesRow>

                {articles.length > visible && (
                  <Row>
                    <Col
                      lg={{ size: 12, offset: 0 }}
                      md={{ size: 12 }}
                      sm={{ size: 12 }}
                    >
                      <ViewAllButton
                        to="/"
                        onClick={() => setVisible(visible + 6)}
                      >
                        {t("articlesComponent.showMoreExercises")}
                      </ViewAllButton>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
        </BootstrapContainer>
        <FullScreen toggle={modal}>
          <Row
            style={{
              margin: 0,
            }}
          >
            <InnerContainer
              spaceBetween
              style={{
                paddingLeft: "5rem",
                paddingRight: "5rem",
                maxHeight: "9vh",
              }}
            >
              <a href="/">
                <img
                  src={dataFilter.logo.publicURL}
                  alt="Logo Tobsos"
                  style={{
                    width: "10rem",
                    marginTop: "2rem",
                    marginRight: "4rem",
                  }}
                />
              </a>

              <MenuItemIcon onClick={() => toggle()}>
                <img
                  src={dataFilter.close.publicURL}
                  alt="search icon"
                  style={{ width: "4rem", marginTop: "1rem" }}
                />
              </MenuItemIcon>
            </InnerContainer>
          </Row>
          <Row style={{ overflowX: "hidden", height: "80vh" }}>
            <Container fluid>
              <Row>
                <Col
                  xs={{ size: 12 }}
                  style={{
                    margin: "15px",
                    paddingRight: "40px",
                  }}
                >
                  <Spacer height={10} tHeight={10} mHeight={11} />
                  {filters.map(filter => (
                    <Filter
                      key={filter.key}
                      categoryKey={filter.id}
                      title={filter.title}
                      categories={filter.categories}
                      handleApplyFilters={handleApplyFilters}
                    />
                  ))}
                </Col>
              </Row>
            </Container>
          </Row>
          <Row
            style={{
              height: "11vh",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Col style={{ maxWidth: "28rem" }}>
              <FilterButton onClick={() => toggle()}>
                {t("articlesComponent.applyFilters")}
              </FilterButton>
            </Col>
          </Row>
        </FullScreen>
      </SectionContainer>
    </>
  )
}

export default Articles
