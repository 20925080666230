import * as React from "react"
import { useEffect, useState } from "react"
import { Collapse } from "reactstrap"
import {
  CheckCategory,
  CheckMark,
  FilterContainer,
  FilterTitle,
} from "./fitler.style"

const Filter = ({
  categoryKey,
  title,
  title_RO,
  categories,
  handleApplyFilters,
}) => {
  const [collapse, setCollapse] = useState({})
  const [checked, setChecked] = useState({})
  useEffect(() => {
    const newChecked = {}
    const newCollapsed = {}
    categories.map(category => {
      newChecked[category.id] = getFilteredCategory(category.id)
      if (category.sub_categories) {
        category.sub_categories.map(subCategory => {
          newChecked[subCategory.id] = getFilteredCategory(subCategory.id)
          if (newChecked[subCategory.id]) {
            newCollapsed[category.id] = newCollapsed[category.id]
              ? !newCollapsed[category.id]
              : true
          }
        })
      }
    })
    setCollapse(newCollapsed)
    setChecked(newChecked)
  }, [])
  const isBrowser = typeof window !== "undefined"

  const handleSetFilter = id => {
    const settedCategories =
      isBrowser && window && window.localStorage.getItem("filters")
    const parsedObject = JSON.parse(settedCategories)

    const value =
      parsedObject && categoryKey in parsedObject
        ? id in parsedObject[categoryKey]
          ? !parsedObject[categoryKey][id]
          : true
        : true
    const newCategories = JSON.stringify({
      ...parsedObject,
      [categoryKey]: {
        ...(parsedObject &&
          categoryKey in parsedObject && {
            ...parsedObject[categoryKey],
          }),
        [id]: value,
      },
    })
    isBrowser && window && window.localStorage.setItem("filters", newCategories)
    setChecked({ ...checked, [id]: value })
    handleApplyFilters()
  }

  const getFilteredCategory = id => {
    const settedCategories =
      isBrowser && window && window.localStorage.getItem("filters")
    const parsedObject = JSON.parse(settedCategories)
    const value =
      parsedObject && categoryKey in parsedObject
        ? id in parsedObject[categoryKey]
          ? parsedObject[categoryKey][id]
          : false
        : false
    return value
  }
  return (
    <FilterContainer>
      <FilterTitle>
        {isBrowser && window && window.localStorage.getItem("lang") === "en"
          ? title
          : title_RO}
      </FilterTitle>
      {categories.map(category =>
        !category.sub_categories ? (
          <CheckCategory
            key={category.id}
            onClick={() => handleSetFilter(category.id)}
          >
            <CheckMark isChecked={checked[category.id]} />
            {isBrowser && window && window.localStorage.getItem("lang") === "en"
              ? category.title
              : category.title_RO}
          </CheckCategory>
        ) : (
          <>
            <CheckCategory
              key={category.id}
              isExpand
              isOpen={!!collapse[category.id]}
              onClick={() =>
                setCollapse({
                  ...collapse,
                  [category.id]: collapse[category.id]
                    ? !collapse[category.id]
                    : true,
                })
              }
            >
              {/* <CheckMark isChecked={checked[category.id]} /> */}
              {isBrowser &&
              window &&
              window.localStorage.getItem("lang") === "en"
                ? category.title
                : category.title_RO}
            </CheckCategory>
            <Collapse isOpen={!!collapse[category.id]}>
              {category.sub_categories.map(subCategory => (
                <CheckCategory
                  isSubCategory
                  key={subCategory.id}
                  onClick={() => handleSetFilter(subCategory.id)}
                >
                  <CheckMark isChecked={checked[subCategory.id]} />
                  {isBrowser &&
                  window &&
                  window.localStorage.getItem("lang") === "en"
                    ? subCategory.title
                    : subCategory.title_RO}
                </CheckCategory>
              ))}
            </Collapse>
          </>
        )
      )}
    </FilterContainer>
  )
}

export default Filter
