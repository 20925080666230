import styled from "styled-components"
import { colors } from "../../../../consts/colors"
import {
  H2 as Title,
  Container as C,
  Col as DC,
} from "../../../common/common.style"

export const Container = styled(C)`
  padding-top: 22rem;
  @media (max-width: 767px) {
    padding-top: 16rem;
  }
`

export const H2 = styled(Title)`
  margin-bottom: 0;
  @media (max-width: 767px) {
    margin-bottom: 4rem;
  }
`

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 38rem;
  position: relative;
  img {
    display: block;
    position: absolute;
    width: wrem;
    height: 2rem;
    left: 2rem;
    top: 1.4rem;
    cursor: pointer;
  }
  margin-bottom: 4rem;
  margin-top: 2rem;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: ${colors.grey};
  border: 1px solid ${colors.grey};
  padding: 1rem 1rem;
  padding-left: 6rem;
  border-radius: 0.75rem;
`

export const Clear = styled.span`
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 2rem;
  top: 1rem;
  display: none;
  background-image: url(${({ bg }) => bg && bg});
  ${({ show }) => show && `display: block`};
`

export const ColAligned = styled(DC)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`
