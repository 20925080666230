import styled from 'styled-components';
import { colors } from '../../consts/colors';
import Link from 'gatsby-plugin-transition-link';

export const ModalWrapper = styled.div`
  border-radius: 2rem;

  background: #fff;
  width: 40%;
  max-width: 800px;
  margin: auto auto;
  transition: all 0.5s ease;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
`;

export const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-right: 2rem;
  font-size: 24px;
  line-height: 28px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  cursor: pointer;
  color: ${colors.white};
  min-width: 180px;
  padding: 10px 40px;
  border-radius: 40px;
  background-color: ${colors.russianblack};
  box-shadow: 1px 1px 5px 0 rgb(1, 1, 1, 0.5);
  &:hover {
    text-decoration: none;
    color: ${colors.white};
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
  @media (max-width: 767px) {
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    margin-right: 0;
  }
`;

export const ConfirmButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 2rem;
  font-size: 24px;
  line-height: 28px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  cursor: pointer;
  color: ${colors.white};
  min-width: 180px;
  padding: 10px 40px;
  border-radius: 40px;
  border-color: ${colors.freespeechred};
  background-color: ${colors.freespeechred};
  box-shadow: 1px 1px 5px 0 ${colors.freespeechred};
  &:hover {
    text-decoration: none;
    color: ${colors.white};
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
  @media (max-width: 767px) {
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    margin-left: 0;
  }
`;

export const ModalContent = styled.div`
  padding: 1.5rem 1rem;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const BodyTitle = styled.h1`
  display: block;
  font-size: 4rem;
  color: ${colors.freespeechred};
  margin: 3rem auto;
  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;
export const Input = styled.input`
  display: block;
  width: 70%;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin: 2rem auto;
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${colors.russianblack};
  border: 1px solid ${colors.grey};
  padding: 1rem 1rem;
  border-radius: 0.75rem;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
