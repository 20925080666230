import styled from "styled-components"

export const FilterContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  margin-bottom: 4rem;
  box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
  border-bottom: 1px solid black;
`

export const FilterTitle = styled.div`
  font-family: "Poppins", serif;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
`
export const CheckMark = styled.div`
  display: inline-block;
  border: 2px solid black;
  padding: 5px;
  ${({ isChecked }) => isChecked && `background-color: #FD626A;`};

  border-radius: 0.4rem;
  margin: auto;
  margin-right: 10px;
`

export const CheckCategory = styled.div`
  font-family: "Poppins", serif;
  text-align: left;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  ${({ isOpen }) =>
    isOpen &&
    `::after {
              content: '▲' !important;
            }
      color: #FD626A;
  `};
  ${({ isExpand }) =>
    isExpand &&
    `::after {
    content: '▼';
    position:absolute;
    right:0;
    top:0;
    }

    `};
  ${({ isSubCategory }) =>
    isSubCategory &&
    `margin-left:20px;
  }`};
`
