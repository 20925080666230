import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import '../components/common/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import TopBar from '../components/common/topBar';
import Layout from '../components/layout';
import Articles from '../components/sections/blog/articles/articles';
import { Footer } from '../components/sections/footer/footer';
import Seo from '../components/seo';

const isBrowser = typeof window !== 'undefined';

const readingTimeProcessing = (text) => {
  const wordCount = text.replace(/[^\w ]/g, '').split(/\s+/).length;
  return Math.ceil(wordCount / 170) + 1 + ' min';
};

const isSearched = (keywords, blog) => {
  let exists = false;

  keywords.forEach((keyword) => {
    if (
      blog.node &&
      blog.node.title !== null &&
      blog.node.title.toLowerCase().includes(keyword.toLowerCase())
    ) {
      exists = true;
    }
    if (
      blog.node &&
      blog.node.description !== null &&
      blog.node.description.toLowerCase().includes(keyword.toLowerCase())
    ) {
      exists = true;
    }
    if (
      blog.node &&
      blog.node.content !== null &&
      blog.node.content.toLowerCase().includes(keyword.toLowerCase())
    ) {
      exists = true;
    }
  });
  return exists;
};

const formatDate = (date_blog) => {
  let formmated_date = new window.Date(date_blog);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    monthNames[formmated_date.getMonth()] +
    ' ' +
    formmated_date.getDate() +
    ', ' +
    formmated_date.getFullYear()
  );
};

const processBlogs = (blogNodes, searchInput) => {
  const blogs = [];
  const keywords = searchInput.split(' ');

  blogNodes.allStrapiExercises.edges.forEach((element) => {
    if (searchInput === '' || isSearched(keywords, element)) {
      blogs.push({
        image: element.node.featured_image.childImageSharp.fluid.src,
        date: formatDate(element.node.published_at),
        readingTime: readingTimeProcessing(element.node.content),
        title: element.node.title,
        description: element.node.content,
        Paid: element.node.Paid,
        slug: element.node.slug,
        categories: element.node.categories,
        age_categories: element.node.age_categories,
        sub_categories: element.node.sub_categories,
      });
    }
  });

  return blogs;
};
const processFilters = (exerciseNodes, filters) => {
  const blogs = [];
  exerciseNodes.allStrapiExercises.edges.forEach(({ node: exercise }) => {
    let hasCategory = false;
    let filterExists = false;
    for (const [key, value] of Object.entries(filters)) {
      const sub_filters = value;
      for (const [key, value] of Object.entries(sub_filters)) {
        value && (filterExists = true);
        if (key.includes('Age-categories')) {
          exercise.age_categories.forEach((age_cat) => {
            if (age_cat.id.toString() === key[key.length - 1] && value) {
              hasCategory = true;
            }
          });
        } else if (key.includes('Sub-categories')) {
          exercise.sub_categories.forEach((sub_cat) => {
            if (sub_cat.id.toString() === key[key.length - 1] && value) {
              hasCategory = true;
            }
          });
        } else {
          exercise.categories.forEach((cat) => {
            if (cat.id.toString() === key && value) {
              hasCategory = true;
            }
          });
        }
      }
    }
    if (!filterExists) {
      blogs.push({
        image: exercise.featured_image.childImageSharp.fluid.src,
        date: formatDate(exercise.published_at),
        readingTime: readingTimeProcessing(exercise.content),
        title: exercise.title,
        description: exercise.content,
        Paid: exercise.Paid,
        slug: exercise.slug,
        categories: exercise.categories,
        age_categories: exercise.age_categories,
        sub_categories: exercise.sub_categories,
      });
    } else if (hasCategory) {
      blogs.push({
        image: exercise.featured_image.childImageSharp.fluid.src,
        date: formatDate(exercise.published_at),
        readingTime: readingTimeProcessing(exercise.content),
        title: exercise.title,
        description: exercise.content,
        Paid: exercise.Paid,
        slug: exercise.slug,
        categories: exercise.categories,
        age_categories: exercise.age_categories,
        sub_categories: exercise.sub_categories,
      });
    }
  });
  return blogs;
};

const Blog = () => {
  const [articles, setArticles] = useState(undefined);
  const [searchInput, setSearchInput] = useState('');
  const data = useStaticQuery(graphql`
    query {
      allStrapiExercises(sort: { fields: id, order: ASC }) {
        edges {
          node {
            id
            title
            title_RO
            content
            slug
            published_at
            description
            Paid
            age_categories {
              id
              title
              title_RO
            }
            categories {
              id
              title
              title_RO
              filter
            }
            sub_categories {
              id
              title
              title_RO
            }
            featured_image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                  src
                }
              }
            }
          }
        }
      }
      code: allStrapiRequiredCodes {
        edges {
          node {
            Code
          }
        }
      }
    }
  `);

  useEffect(() => {
    data && setArticles(processBlogs(data, ''));
    setSearchInput('');
  }, [data]);

  useEffect(() => {
    handleApplyFilters();
  }, []);

  const handleApplyFilters = () => {
    const settedCategories =
      isBrowser && window && window.localStorage.getItem('filters');
    const parsedFilters = JSON.parse(settedCategories);
    data && parsedFilters && setArticles(processFilters(data, parsedFilters));
  };

  const debounceSetNewArticles = useRef(
    debounce((data, searchInput) => {
      data && setArticles(processBlogs(data, searchInput));
      data && setSearchInput(searchInput);
    }, 400)
  ).current;

  const handleSearchChange = (searchInput) => {
    debounceSetNewArticles(data, searchInput);
    setSearchInput(searchInput);
  };

  const clearSeachInput = () => {
    setSearchInput('');
    data && setArticles(processBlogs(data, ''));
  };

  const codes = data.code.edges;

  return (
    <>
      <Layout>
        <Seo
          title={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Tobsos - Exerciții'
              : 'Tobsos - Exercises'
          }
          description={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top.'
              : 'The stronger the players physical foundation the higher the chances of becoming a top athlete.'
          }
          keywords="fitness, training, sport, young, tineret"
          url="https://tobsos.com/"
        />
        <TopBar />

        {articles && articles !== undefined ? (
          <>
            <Articles
              articles={articles}
              searchInput={searchInput}
              clearSeachInput={clearSeachInput}
              handleSearchChange={handleSearchChange}
              handleApplyFilters={handleApplyFilters}
              promoCodes={codes}
            />
            <Footer />
          </>
        ) : (
          <div
            style={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader
              type="Grid"
              color="#D50000"
              height={100}
              width={100}
              timeout={2000} //3 secs
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default Blog;
