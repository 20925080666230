import React, { useState } from 'react';
import {
  ModalWrapper,
  ModalContent,
  ModalBody,
  BodyTitle,
  Input,
  ModalFooter,
  CloseButton,
  ConfirmButton,
} from './Modal.style';
import { useTranslation } from 'react-i18next';
const isBrowser = typeof window !== 'undefined';

export const Modal = ({ showModal, setShowModal, promoCodes }) => {
  const [inputValue, setInpuValue] = useState('');
  const { t, i18n } = useTranslation();
  function handleChange(value) {
    setInpuValue(value);
  }

  function handleClick() {
    if (promoCodes.some((e) => e.node.Code === inputValue)) {
      window.localStorage.setItem('codeActive', 'true');
      window.location.href =
        '/exercise/' + window.localStorage.getItem('toLink');
    } else {
      alert('Cod Incorect!');
    }

    setShowModal(true);
  }

  return (
    <>
      {showModal ? (
        <div
          style={{
            background: 'rgba(54, 69, 79, 0.95)',
            height: '100%',
            position: 'fixed',
            transition: 'all 1.3s',
            display: 'flex',
            width: '100%',
            zIndex: '999',
          }}
        >
          <ModalWrapper>
            <ModalContent>
              <ModalBody>
                <BodyTitle>{t('modalComponent.enterPromoCode')}</BodyTitle>

                <Input
                  onChange={(e) => handleChange(e.target.value)}
                  value={inputValue}
                />
              </ModalBody>
              <ModalFooter>
                <CloseButton
                  onClick={() => {
                    setShowModal(false);
                    setInpuValue('');
                  }}
                >
                  {t('modalComponent.close')}
                </CloseButton>
                <ConfirmButton onClick={handleClick}>
                  {t('modalComponent.confirm')}
                </ConfirmButton>
              </ModalFooter>
            </ModalContent>
          </ModalWrapper>
        </div>
      ) : null}
    </>
  );
};
