import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../../../consts/colors';

export const SectionContainer = styled(Container)`
  padding-top: 12rem;
  padding-bottom: 12rem;
  @media (max-width: 1024px) {
    padding-top: 12rem;
    padding-bottom: 4rem;
  }
  @media (max-width: 767px) {
    padding-top: 12rem;
    padding-bottom: 1rem;
  }
`;

export const ExercisesRow = styled(Row)`
  display: flex;

  justify-content: stretch;
`;

export const ExerciseCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  display: block;
  z-index: 9999;
  overflow-y: visible;
  ${({ toggle }) =>
    !toggle &&
    `display: none;   
  }`};
`;

export const FeaturedImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 64%;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
  margin-bottom: 2rem;
  /*background-image: url(${({ src }) => src && src});
  background-size: cover;
  background-position: center center;*/
  transition: box-shadow 0.3s;
  ${({ isPaid }) =>
    isPaid &&
    `
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); 
  }`};
`;

export const FeaturedImage = styled.img`
  display: block;
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s;
`;

export const Date = styled.span`
  display: block;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.grey};
  margin-bottom: 1rem;
`;

export const ArticleTitle = styled.h3`
  font-family: 'Poppins', serif;
  font-size: 2.4rem;
  line-height: 3.3rem;
  color: ${colors.jetblack};
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
`;

export const Meta = styled.p`
  display: block;
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.grey};
`;

export const ViewAllButton = styled.span`
  margin: auto;
  display: block;
  width: 38rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.56;
  text-align: center;
  cursor: pointer;
  color: ${colors.jetblack};
  border: 1px solid ${colors.jetblack};
  border-radius: 3rem;
  padding: 1.6rem 4rem;
  &:hover {
    color: ${colors.jetblack};
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ArticleWrap = styled(Link)`
  background-color: white;
  display: block;
  width: 100%;
  margin-bottom: 8rem;
  text-decoration: none;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 767px) {
    margin-bottom: 4rem;
  }
  &:hover {
    text-decoration: none;
  }
  &:hover ${FeaturedImageWrapper} {
    box-shadow: 0 20px 40px -20px rgba(41, 41, 41, 0.3);
    text-decoration: none;
    img {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }
`;

export const CardSubtitle = styled.p`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;

  font-weight: 600;
  margin-bottom: 0px;
  color: ${colors.freespeechred};
`;

export const Cardparagraph = styled.p`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 19px;
  margin-bottom: 10px;
  padding: 0px 3px;
  max-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.russianblack};
  :last-child {
    margin-bottom: 15px;
  }
`;

export const SeeExercise = styled.div`
  display: block;
  align-self: flex-end;
  background-color: ${({ color }) => (color ? color : colors.russianblack)};
  color: white;
  text-align: center;
  padding: 9px 4px;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 5px;
  /* margin-bottom: 40px; */
  border-radius: 7px;

  &:hover {
    text-decoration: none;
    color: ${colors.white};
    background-color: ${colors.freespeechred};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
`;
