import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Clear, Input, SearchWrapper } from "./header.style";

const SearchInput = ({ handleSearchChange, clearSeachInput, searchInput }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== searchInput) setValue(searchInput);
  }, [searchInput, value]);

  const images = useStaticQuery(graphql`
    query blog {
      search: file(relativePath: { eq: "blog/search.svg" }) {
        id
        extension
        publicURL
      }
      clear: file(relativePath: { eq: "blog/clear.svg" }) {
        id
        extension
        publicURL
      }
    }
  `);

  function handleChange(value) {
    setValue(value);
    handleSearchChange(value);
  }

  function handleEnter(e) {
    if (e.key === "Enter") {
      setValue(value);
      handleSearchChange(value);
    }
  }

  return (
    <SearchWrapper>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <Input
        placeholder={t("headerComponent.searchExercises")}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleEnter(e.target.value)}
      />
      {/* eslint-disable-next-line */}
      <img
        src={images.search.publicURL}
        alt="search icon"
        style={{ width: "2rem" }}
      />
      <Clear
        bg={images.clear.publicURL}
        show={value === "" ? false : true}
        onClick={clearSeachInput}
      />
    </SearchWrapper>
  );
};

export default SearchInput;
